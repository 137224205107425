/* RaceResult.css */

.table-container {
  margin: 20px auto;
  max-width: 800px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
}

.table th,
.table td {
  padding: 10px;
  text-align: center;
}

.table th {
  background-color: #333;
  color: #fff;
  font-weight: bold;
  /* position: sticky; */
  top: 0;
}

.table td {
  border-bottom: 1px solid #ddd;
}

.table tr:hover {
  background-color: #f5f5f5;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:last-child td {
  border-bottom: none;
}

@media only screen and (max-width: 600px) {
  .table th,
  .table td {
    padding: 5px;
    font-size: 12px;
  }
}
